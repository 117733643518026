<template>
  <v-content>
    <v-container class="d-print-none" grid-list-lg fluid>
      <v-layout wrap align-start justify-center row fill-height>
        <v-flex xs12>
          <AppTitle title="Listado de Insumos" :subtitle="`${allInsumos.length} insumos en total`"></AppTitle>
        </v-flex>

        <v-flex v-if="$mq !== 'sm'" xs12>
          <v-card>
            <AppTitleCard>
              <template slot="title">
                En total
                <strong class="secondary--text"> {{ insumos.length }}</strong>
                insumos
              </template>
              <template slot="button">
                <v-text-field
                  v-model="inputSearch"
                  label="Buscar insumos en listado"
                  solo-inverted
                  color="info"
                  flat
                  hide-details
                  append-icon="search"
                  @keyup.native="searchInputHandleList"
                ></v-text-field>
              </template>
            </AppTitleCard>
            <v-data-table
              :loading="loading"
              :disable-initial-sort="true"
              :rows-per-page-items="[50, 75, 100, 200]"
              :headers="headers"
              :items="insumos"
            >
              <template slot="items" slot-scope="props">
                <tr @click="modifyInsumo(props.item)">
                  <td class="text-xs-center">
                    <v-img
                      v-if="props.item.photo != ''"
                      :src="props.item.photo"
                      :lazy-src="props.item.photo"
                      aspect-ratio="4"
                      style="width:100px; height: 100px;border-radius: 100%;"
                      class="grey lighten-2 my-2 mx-0"
                    >
                      <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-layout>
                    </v-img>
                  </td>

                  <td class="text-xs-right">{{ props.item.codigo }}</td>
                  <td class="text-xs-left">{{ props.item.type }}</td>
                  <td class="text-xs-left">{{ props.item.name }}</td>
                  <td class="text-xs-right">{{ props.item.stock }} {{ props.item.um }}</td>
                  <td class="text-xs-right">{{ props.item.receta }}</td>
                  <td class="text-xs-right">
                    <v-btn icon @click="activeInsumo(props.index)">
                      <v-icon v-if="props.item.active">done</v-icon>
                      <v-icon v-else>clear</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>

        <v-flex v-if="$mq === 'sm'" xs12>
          <v-data-iterator :items="insumos" :rows-per-page-items="[50, 75, 100, 200]" content-tag="v-layout" row wrap>
            <v-flex slot="item" slot-scope="props" xs12 sm6 md4 lg3 @click="modifyInsumo(props.item)">
              <v-card v-ripple class="mb-2">
                <v-card-title>
                  <h4>
                    {{ props.item.name }} - {{ props.item.codigo }} <br />
                    <small>{{ props.item.type }}</small>
                  </h4>
                </v-card-title>
                <v-divider></v-divider>
                <v-list dense>
                  <v-list-tile>
                    <v-list-tile-content>Recetas:</v-list-tile-content>
                    <v-list-tile-content class="align-end">{{ props.item.receta }}</v-list-tile-content>
                  </v-list-tile>
                  <template v-if="props.item.warehouseStock.length > 0">
                    <v-list-tile
                      v-for="(warehouse, c) in props.item.warehouseStock"
                      :key="`${props.item.id}${c}${warehouse.name}`"
                    >
                      <v-list-tile-content>{{ warehouse.name }}: </v-list-tile-content>
                      <v-list-tile-content class="align-end"
                        >{{ warehouse.stock }} {{ props.item.um }}</v-list-tile-content
                      >
                    </v-list-tile>
                  </template>
                  <template v-if="props.item.storeStock.length > 0">
                    <v-list-tile v-for="(store, d) in props.item.storeStock" :key="`${props.item.id}${d}${store.name}`">
                      <v-list-tile-content>{{ store.name }}:</v-list-tile-content>
                      <v-list-tile-content class="align-end">{{ store.stock }} {{ props.item.um }}</v-list-tile-content>
                    </v-list-tile>
                  </template>
                  <v-list-tile v-if="props.item.active">
                    <v-list-tile-content>Estado:</v-list-tile-content>
                    <v-list-tile-content class="align-end">
                      <v-btn icon @click="activeInsumo(props.index)">
                        <v-icon v-if="props.item.active">done</v-icon>
                        <v-icon v-else>clear</v-icon>
                      </v-btn>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
          </v-data-iterator>
        </v-flex>
      </v-layout>

      <v-dialog v-model="dialogSearch" persistent max-width="300px">
        <v-card>
          <v-card-title>
            <span class="headline"> Buscar insumo </span>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 sm12 md12>
                <v-text-field v-model="searchInput" label="Nombre o Codigo del insumo" outline clearable></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="default" flat @click.native="dialogSearch = false">Volver</v-btn>
            <v-btn color="primary" flat @click.native="searchInputHandle">Buscar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogFilter" persistent max-width="300px">
        <v-card>
          <v-card-title>
            <span class="headline"> Filtrar insumos </span>
          </v-card-title>
          <v-card-text>
            Filtros
          </v-card-text>
          <v-card-actions>
            <v-btn color="default" flat @click.native="dialogFilter = false">Volver</v-btn>
            <v-btn color="primary" flat @click.native="dialogFilter = false">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogEdit"
        :fullscreen="$mq === 'sm'"
        :hide-overlay="$mq === 'sm'"
        persistent
        max-width="700px"
      >
        <v-card class="d-print-none">
          <v-toolbar class="elevation-0" color="secondary" dark>
            <v-toolbar-title>
              <span v-if="insumo.id === undefined"> Nuevo Insumo </span>
              <span v-else> Modificar {{ insumo.name }} </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat @click="printQR">
              IMPRIMIR QR
            </v-btn>
            <v-btn flat icon @click="closeInsumo">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-container fluid grid-list-lg>
              <v-layout wrap>
                <v-flex xs12>
                  <template v-if="insumo.photo != '' && insumo.photo !== undefined">
                    <v-flex xs12 class="text-xs-center">
                      <h2 class="title">Foto Insumo</h2>
                      <br />
                      <img :src="insumo.photo" alt="" :width="200" :height="200" />
                    </v-flex>
                    <v-flex v-if="!inactive" xs12 class="text-xs-center">
                      <v-btn color="success" @click="insumo.photo = ''">
                        Cambiar
                      </v-btn>
                    </v-flex>
                  </template>
                  <template v-else-if="photoUpload === ''">
                    <v-flex xs12 class="text-xs-center">
                      <croppa
                        v-model="photoCut"
                        style="margin:0 auto;"
                        :width="200"
                        :height="200"
                        :quality="3"
                        disable-click-to-choose
                        :prevent-white-space="true"
                        :zoom-speed="8"
                        initial-position="center"
                        placeholder="Avatar"
                        placeholder-color="#000"
                        :placeholder-font-size="24"
                        canvas-color="#eee"
                        :show-remove-button="true"
                        remove-button-color="black"
                        :remove-button-size="30"
                        :show-loading="true"
                        :loading-size="20"
                      >
                      </croppa>
                    </v-flex>
                    <v-flex v-if="!inactive" xs12 class="text-xs-center">
                      <v-btn color="accent" @click="chooseFile">
                        Elegir archivo...
                      </v-btn>
                      <v-btn color="success" @click="photoUpload = photoCut.generateDataUrl()">
                        Generar Foto
                      </v-btn>
                    </v-flex>
                  </template>
                  <template v-else>
                    <v-flex xs12 class="text-xs-center">
                      <h2 class="title">Foto Insumo</h2>
                      <br />
                      <img :src="photoUpload" alt="" :width="200" :height="200" />
                    </v-flex>
                    <v-flex xs12 class="text-xs-center">
                      <v-btn color="success" @click="photoUpload = ''">
                        Cambiar
                      </v-btn>
                    </v-flex>
                  </template>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="insumo.name" label="Nombre"></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="insumo.codigo"
                    :disabled="insumo.codigo != undefined && insumo.id != undefined ? true : false"
                    label="Codigo"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-select v-model="insumo.type" :items="itemsCategories" label="Categoria"></v-select>
                </v-flex>
                <v-flex xs4>
                  <v-select v-model="insumo.codeContable" :items="itemsCodes" label="Codigo contable"></v-select>
                </v-flex>

                <v-flex xs4>
                  <v-select v-model="insumo.um" :items="itemsUM" label="Unidad de medida"></v-select>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="insumo.price" label="Precio (por defecto)"></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="insumo.por_caja" label="Por Caja (por defecto)"></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-switch v-model="insumo.diseno" label="Diseño (Panel de diseño*)" :value="1"></v-switch>
                </v-flex>
                <v-flex xs6>
                  <v-switch v-model="insumo.active" label="Activo" :value="1"></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions v-if="!inactive">
            <v-spacer></v-spacer>
            <v-btn color="default" flat @click.native="dialogEdit = false">Volver</v-btn>
            <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="createInsumo">
              <span v-if="insumo.id === undefined"> Crear </span>
              <span v-else> Actualizar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-tooltip top>
        <v-btn v-if="!inactive" slot="activator" color="accent" fixed bottom right fab @click="newInsumo">
          <v-icon>add</v-icon>
        </v-btn>
        <span>Nuevo Insumo</span>
      </v-tooltip>
    </v-container>
    <div v-if="insumo.id" style="margin: 20px auto 0 auto;width: 128px;" class="d-print-block">
      <div>
        <QrcodeVue :value="insumo.codigo" size="120" level="H" />
      </div>
      <div class="text-xs-center">
        <h3>{{ insumo.type }}</h3>
        <h4>{{ insumo.name }}</h4>
        <p>{{ insumo.codigo }}</p>
      </div>
    </div>
  </v-content>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

import { GETINSUMOS, GET_INSUMOS } from '@/config'
import AppTitle from '../useful/title'
import AppTitleCard from '../useful/titleCard.vue'

export default {
  name: 'Insumos',
  components: { AppTitle, QrcodeVue, AppTitleCard },
  data() {
    return {
      headers: [
        { text: 'Foto', align: 'left', sortable: false },
        { text: 'Codigo', align: 'right', sortable: false },
        { text: 'Categoria', sortable: false },
        { text: 'Nombre', sortable: false },
        { text: 'Stock', align: 'right', sortable: false },
        { text: 'Receta (Nº productos)', sortable: false, align: 'right' },
        { text: 'Estado', sortable: false, align: 'center' }
      ],
      itemsCodes: [
        { value: '1.1.1080.20.01', text: '1.1.1080.20.01 | Materiales' },
        { value: '1.1.1080.20.02', text: '1.1.1080.20.02 | Accesorios' },
        { value: '1.1.1080.20.03', text: '1.1.1080.20.03 | Insumos varios' },
        { value: '1.1.1080.20.04', text: '1.1.1080.20.04 | Flores' }
      ],
      itemsCategories: [
        { value: 'BASES - CERAMICAS', text: 'BASES - CERAMICAS' },
        { value: 'BASES - MADERA', text: 'BASES - MADERA' },
        { value: 'BASES - METALICA', text: 'BASES - METALICA' },
        { value: 'BASES - VIDRIOS', text: 'BASES - VIDRIOS' },
        { value: 'FLORES', text: 'FLORES' },
        { value: 'FLORES - ROSAS', text: 'FLORES - ROSAS' },
        { value: 'FLORES - TULIPANES', text: 'FLORES - TULIPANES' },
        { value: 'FLORES - VERDE', text: 'FLORES - VERDE' },
        { value: 'HH', text: 'HH' },
        { value: 'INSUMOS', text: 'INSUMOS' },
        { value: 'INSUMOS - CAJAS', text: 'INSUMOS - CAJAS' },
        { value: 'INSUMOS - CINTA', text: 'INSUMOS - CINTA' },
        { value: 'INSUMOS - ESPUMA FLORAL', text: 'INSUMOS - ESPUMA FLORAL' },
        { value: 'INSUMOS - MALLA', text: 'INSUMOS - MALLA' },
        { value: 'INSUMOS - MERCHANDISING', text: 'INSUMOS - MERCHANDISING' },
        { value: 'INSUMOS - PAPEL', text: 'INSUMOS - PAPEL' },
        { value: 'INSUMOS - VELA', text: 'INSUMOS - VELA' },
        { value: 'REGALOS - CHOCOLATES', text: 'REGALOS - CHOCOLATES' },
        { value: 'REGALOS - GLOBO AIRE', text: 'REGALOS - GLOBO AIRE' },
        { value: 'REGALOS - GLOBO HELIO', text: 'REGALOS - GLOBO HELIO' },
        { value: 'REGALOS - GLOBO LATEX', text: 'REGALOS - GLOBO LATEX' },
        { value: 'REGALOS - GLOBO VARILLAS', text: 'REGALOS - GLOBO VARILLAS' },
        { value: 'REGALOS - LIBROS', text: 'REGALOS - LIBROS' },
        { value: 'REGALOS - PELUCHES', text: 'REGALOS - PELUCHES' },
        { value: 'REGALOS - ROPA', text: 'REGALOS - ROPA' },
        {
          value: 'REGALOS - ROSAS PRESERVADAS 1',
          text: 'REGALOS - ROSAS PRESERVADAS 1'
        },
        {
          value: 'REGALOS - ROSAS PRESERVADAS 6',
          text: 'REGALOS - ROSAS PRESERVADAS 6'
        },
        { value: 'REGALOS - SET ', text: 'REGALOS - SET ' }
      ],
      itemsUM: [
        { value: 'unid', text: 'Unidad' },
        { value: 'kg', text: 'Kilogramos' },
        { value: 'mt', text: 'Metros' },
        { value: 'm3', text: 'Metros Cubicos (m3)' },
        { value: 'pl', text: 'Pliegos' },
        { value: 'vara', text: 'Vara' }
      ],
      inputSearch: '',
      dialogSearch: false,
      dialogEdit: false,
      dialogFilter: false,
      loading: false,
      title: 'Insumos',
      insumo: {},
      allInsumos: [],
      insumos: [],
      searchInput: null,
      photoCut: null,
      photoUpload: '',
      inactive: true
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  watch: {
    $mq() {
      this.setTitle()
    },
    'update.action': function() {
      this.getInfo()
    },
    'search.text': function(e) {
      this.searchInputHandle(e)
    },
    dialogSearch() {
      if (!this.dialogSearch) {
        this.$store.dispatch('changeSearch', { active: true, action: false })
      }
    }
  },
  mounted() {
    this.getInfo()
    this.setTitle()
  },
  methods: {
    async getInfo() {
      this.loading = true
      try {
        const res = await this.$http.get(`${GET_INSUMOS}?full=1`)
        this.insumos = res.data
        this.allInsumos = this.insumos
        this.loading = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async createInsumo() {
      this.loading = true
      try {
        const res = await this.$http.post(`${GET_INSUMOS}/new`, {
          insumo: this.insumo,
          photoUpload: this.photoUpload
        })
        if (!res.body.error) {
          this.dialogEdit = false
          this.loading = false
          this.getInfo()
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    activeInsumo(index) {
      this.pagination.items[index].active = !this.pagination.items[index].active
      const param = {
        p: 'updateActiveInsumo',
        insumo: this.pagination.items[index]
      }
      this.$http.post(GETINSUMOS, param, {
        emulateJSON: true
      })
    },
    setTitle() {
      this.$store.dispatch('updateTitle', 'Inventario')
      if (this.$mq === 'sm') {
        this.$store.dispatch('updateTitle', '')
      }
    },
    chooseFile() {
      this.photoCut.chooseFile()
    },
    modifyInsumo(item) {
      const note = document.querySelector('html')
      note.style.overflow = 'hidden'
      this.insumo = item
      this.dialogEdit = true
    },
    newInsumo() {
      this.insumo = {}
      this.dialogEdit = true
    },
    searchInputHandle(value) {
      const search = value
      let result = this._.filter(this.allInsumos, item => {
        const res = this._.includes(this._.lowerCase(item.name), this._.lowerCase(search))
        return res
      })
      if (result.length === 0) {
        result = this._.filter(this.allInsumos, item => {
          const res = this._.includes(this._.lowerCase(item.codigo), this._.lowerCase(search))
          return res
        })
      }
      if (result.length > 0) {
        this.insumos = result
      }
      this.dialogSearch = false
    },
    printQR() {
      window.print()
    },
    closeInsumo() {
      const note = document.querySelector('html')
      note.style.overflow = 'auto'
      this.insumo = {}
      this.dialogEdit = false
    },
    searchInputHandleList: _.debounce(function debounce() {
      const search = this.inputSearch

      const result = this._.filter(this.insumos, item => {
        let res = this._.includes(this._.lowerCase(item.name), this._.lowerCase(search))
        if (!res) {
          res = this._.includes(this._.lowerCase(item.codigo), this._.lowerCase(search))
          if (!res) {
            res = this._.includes(this._.lowerCase(item.type), this._.lowerCase(search))
          }
        }
        return res
      })
      console.log(result.length)
      if (result.length > 0 && search !== '') {
        this.insumos = result
      } else {
        this.insumos = this.allInsumos
      }
    }, 500)
  }
}
</script>

<style>
@media print {
  .v-dialog {
    display: none;
  }
  .v-overlay {
    display: none;
  }
  .application .v-content {
    padding: 0px !important;
  }
  .theme--light.application {
    background: #fff;
  }
}
</style>
