import { render, staticRenderFns } from "./supplies.vue?vue&type=template&id=b0a509e4&"
import script from "./supplies.vue?vue&type=script&lang=js&"
export * from "./supplies.vue?vue&type=script&lang=js&"
import style0 from "./supplies.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
import { VDataIterator } from 'vuetify/lib'
import { VDataTable } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VSwitch } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
import { VToolbarTitle } from 'vuetify/lib'
import { VTooltip } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VContainer,VContent,VDataIterator,VDataTable,VDialog,VDivider,VFlex,VIcon,VImg,VLayout,VList,VListTile,VListTileContent,VProgressCircular,VSelect,VSpacer,VSwitch,VTextField,VToolbar,VToolbarTitle,VTooltip})


/* vuetify-loader */
import installDirectives from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import { Ripple } from 'vuetify/lib/directives'
installDirectives(component, {Ripple})
